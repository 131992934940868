/* eslint-disable no-self-compare */
//@ts-nocheck
import React from 'react'
import styled from 'styled-components/macro'
import {useNavigate} from 'react-router-dom'
import {ClickAwayListener, Skeleton} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {getCookie} from 'typescript-cookie'

import Container from '../../components/container/container'
import Table from '../../components/table/Table'
import CustomSelect, {IOption} from '../../components/custom-select/CustomSelect'
import {addStatusIcon, findStatus, hasProperty, rounding_format} from '../../libs/utils/utils'
import Popup from '../../components/popup/popup'
import Button from '../../components/button/button'
import DragNDrop from '../../features/drag-n-drop/drag-n-drop'
import Filter from '../../features/filter/filter'
import {FilterIcon} from '../../assets/images/images'
import {useCreateAppealMutation, useSendCallbackFiatMutation, useSendCallbackMutation} from '../../store/services/appeal-service'
import Pagination from '../../components/pagination/pagination'
import {useFetchOrdersQuery} from '../../store/services/orders-service'
import {IOrdersResponseData, IStatus} from '../../types/types'
import {options} from '../../libs/consts'
import useDebounce from '../../hooks/use-debounce'
import {useLazyRefreshQuery} from '../../store/services/base-service'
import CSVButton from '../../features/csv-button/csv-button'
import TransactionCard from '../../components/transaction-card/TransactionCard'
import CopyableText from '../../components/copyAbleText/copyAbleText'
import {CustomSwiper} from '../../components/custom-swiper/CustomSwiper'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import {useAppSelector} from '../../hooks/redux';

const cryptoActions = [
  { id: 2, label: 'Апелляция', name: 'appeal' },
  { id: 3, label: 'Callback', name: 'callback'},
]

const AppealPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledTextarea = styled.textarea`
  padding: 8px;
  border-radius: 12px;
  border: 1px solid #1b2431;
  resize: none;
  height: 100px;
  width: 100%;
  background-color: #fff;
  color: #000;
  ::placeholder {
    color: #1b2431;
  }
`

const SelectBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  margin: 24px 0px;

  @media screen and (max-width: 1300px) {
    gap: 38px;
  }

  @media screen and (max-width: 1250px) {
    gap: 26px;
  }

  @media screen and (max-width: 1185px) {
    gap: 22px;
  }

  @media screen and (max-width: 1015px) {
    gap: 18px;
  }

  @media screen and (max-width: 980px) {
    gap: 14px;
  }

  @media screen and (max-width: 920px) {
    gap: 6px;
  }

  @media screen and (max-width: 890px) {
    gap: 4px;
  }

  @media screen and (max-width: 870px) {
    gap: 2px;
  }

  @media screen and (max-width: 350px) {
    flex-direction: column;
  }
`

const SelectsWrapper = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
`

const FilterWrapper = styled.div<{ isActive: boolean }>`
  > svg {
    fill: ${({ isActive }) => (isActive ? '#738297' : 'transparent')};
    transition: all 0.3s ease 0s;
  }
  cursor: pointer;
`

const Option = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 10px 22px;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
  text-align: center;
  background: ${({ active }) => (active ? '#fff' : '#fff')};
  color: ${({ active }) => (active ? '#7154DC' : '#989A9E')};

  @media screen and (max-width: 1170px) {
		padding: 10px 15px;
	}

  @media screen and (max-width: 1110px) {
    padding: 10px 8px;
  }
`
const StatusWrapper = styled.div<{ color: string }>`
  font-size: 14px;
  color: ${({ color }) => color};
`

const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const FileNamesWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  ustify-content: space-between;
  align-items: center;
`

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
`

const PaginationWrapper = styled.div`
  background: white;
  padding: 0 20px;
  margin-bottom: 160px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);

  @media screen and (max-width: 768px) {
		background: transparent;
    box-shadow: none;
	}
`

const limitOptions = [
  { name: '10' },
  { name: '50' },
  { name: '100' },
  { name: '200' },
]

const inOutOptions = [
  { name: 'all', label: 'all', status: ''},
  { name: 'in', label: 'in', status: 'IN'},
  { name: 'out', label: 'out', status: 'OUT'},
]

interface MainProps {
  selectedToken?: string
}

const Main: React.FC<MainProps> = (props) => {
  const {selectedToken} = props
  const {t} = useTranslation()
  const windowWidth = window.innerWidth
  const navigate = useNavigate()

  const notifyErrorAppeal = () => toast.error(t('components.errorAppeal'))
  const notifySuccessAppeal = () => toast.success(t('components.successAppeal'))
  const notifyErrorCallback = () => toast.error(t('components.errorCallback'))
  const notifySuccessCallback = () => toast.success(t('components.successCallback'))

  // const { files64 } = useAppSelector((state) => state.base)

  const [card, setCard] = React.useState<string | undefined>(undefined)
  const [id, setId] = React.useState<string | undefined>(undefined)
  const [fiatAmount, setFiatAmount] = React.useState<string | undefined>(undefined)
  const [transationId, setTransationId] = React.useState<string | undefined>(undefined)
  const [mid, setMid] = React.useState<string | undefined>(undefined)
  const [fingerprint, setFingerprint] = React.useState<string | undefined>(undefined)
  const [dateFrom, setDateFrom] = React.useState<string | undefined>(undefined)
  const [dateTo, setDateTo] = React.useState<string | undefined>(undefined)
  const [files64, setFiles64] = React.useState<File[]>([])

  const debouncedCard = useDebounce(card, 500)
  const debouncedId = useDebounce(id, 500)
  const debouncedFiatAmount = useDebounce(fiatAmount, 500)
  const debouncedTransationId = useDebounce(transationId, 500)
  const debouncedMid = useDebounce(mid, 500)
  const debouncedFingerprint = useDebounce(fingerprint, 500)
  const debouncedDateFrom = useDebounce(dateFrom, 500)
  const debouncedDateTo = useDebounce(dateTo, 500)

  const [refresh] = useLazyRefreshQuery()

  const [limit, setLimit] = React.useState<number>(10)
  const [offset, setOffset] = React.useState<number>(0)
  const [page, setPage] = React.useState<number>(1)
  const [status, setStatus] = React.useState<IStatus>(options[4])
  const [activeOption, setActiveOption] = React.useState({
    name: 'all',
    status: '',
  })
  const [appealType, setAppealType] = React.useState('')
  const [typeOption, setTypeOption] = React.useState<'IN' | 'OUT' | ''>('')

  const [isActiveFilter, setIsActiveFilter] = React.useState(false)
  const [isVisiblePopupAppeal, setIsVisiblePopupAppeal] =
    React.useState<boolean>(false)
    const [isVisiblePopupCheck, setIsVisiblePopupCheck] =
    React.useState<boolean>(false)
  const [selectedId, setSelectedId] = React.useState<string>('')
  const [amountFrom, setAmountFrom] = React.useState<string>(0)
  const [appealMessage, setAppealMessage] = React.useState<string>('')
//  const [callbackValue, setCallbackValue] = React.useState<string>('')
  const [isFilterClicked, setIsFilterClicked] = React.useState(true)
  const [checkArray, setCheckArray] = React.useState<string[]>([])
  const [dataTable, setDataTable] = React.useState([])

  const {tx_operations_access, orders_access} = useAppSelector((state) => state.base.accesses)
  const isSubUser = useAppSelector((state) => state.base.isSubUser)

  const [
    createAppeal,
    { isSuccess: isSuccessCreateAppeal, isError: isErrorCreateAppeal },
  ] = useCreateAppealMutation()
  const {
    data: dataHistory,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
    isError: isErrorHistory,
  } = useFetchOrdersQuery(
    {
      limit: limit,
      offset: offset,
      type: typeOption || undefined,
      token_name: getCookie('is_fiat') === 'false' ? selectedToken || undefined : undefined,
      token: getCookie('is_fiat') === 'true' ? selectedToken || undefined : undefined,
      receiver: debouncedCard ? String(debouncedCard) : undefined,
      tracker_id: debouncedId ? String(debouncedId).replace(/[^A-Z0-9]/gi, '') : undefined,
      base_amount: debouncedFiatAmount ? Number(debouncedFiatAmount): undefined,
      status: status?.status ? status?.status : undefined,
      client_transaction_id: debouncedTransationId ? debouncedTransationId : undefined,
      client_merchant_id: debouncedMid ? String(debouncedMid) : undefined,
      fingerprint: debouncedFingerprint ? String(debouncedFingerprint) : undefined,
      date_from: debouncedDateFrom ? new Date(debouncedDateFrom)?.toISOString() : undefined,
      date_to: debouncedDateTo ? new Date(debouncedDateTo)?.toISOString() : undefined,
    },
    { pollingInterval: 15000 },
  )

  React.useEffect(() => {
    setDataTable(getCookie('is_fiat') === 'false' ? dataHistory?.orders : dataHistory?.transactions)
  }, [dataHistory])

  React.useEffect(() => {
    setIsFilterClicked(true)
  }, [selectedToken])

  React.useEffect(() => {
    if (!isFetchingHistory) {
      setIsFilterClicked(false)
    }
  }, [isFetchingHistory, isFilterClicked])

  const [sendCallback] = useSendCallbackMutation()
  const [sendCallbackFiat] = useSendCallbackFiatMutation()

  React.useEffect(() => {
    if (isErrorHistory) {
      refresh(null)
    }
  }, [isErrorHistory])

  React.useEffect(() => {
    if (isSuccessCreateAppeal) {
      notifySuccessAppeal()
      setFiles64([])
    }
    if (isErrorCreateAppeal) {
      notifyErrorAppeal()
      setFiles64([])
    }
  }, [isSuccessCreateAppeal, isErrorCreateAppeal])

  const handleCallback = (id: string) => {
    if (getCookie('is_fiat') === 'true') {
      sendCallbackFiat({tracker_id: id})
      .unwrap()
      .then(() => notifySuccessCallback())
      .catch(() => notifyErrorCallback())
    } else {
      sendCallback({tracker_id: id})
      .unwrap()
      .then(() => notifySuccessCallback())
      .catch(() => notifyErrorCallback())
    }
  }

  const appealHandler = React.useCallback(async () => {
    const result = new FormData()
        result.append('attachment', files64[0])
        result.append('tracker_id', selectedId)
        result.append('message', appealMessage)
        result.append('amount', amountFrom)
        result.append('type', 'DELAY')

    createAppeal({ formData: result })
    setIsVisiblePopupAppeal(false)
    setAppealMessage('')
    setAppealType('')
    setAmountFrom(0)
    setFiles64([])
  }, [selectedId, appealMessage, files64, appealType, amountFrom])

  const handleChangeActiveOption = React.useCallback(
    (option: { name: string; status: string; id: number } ) => {
      setStatus(option)
      setActiveOption(option)
      defaultPageHandler()
    },
    [],
  )

  const handleChangeActiveTypeOption = React.useCallback(
    (value) => {
        setTypeOption(value)
        defaultPageHandler()
    },
    [],
  )

  const handleChangeActiveFilter = React.useCallback(() => {
    setIsActiveFilter(!isActiveFilter)
  }, [isActiveFilter])

  const defaultPageHandler = React.useCallback(() => {
    setPage(1)
    setOffset(0)
    navigate('/')
  }, [])

  const handleOnChangePage = React.useCallback(
    (newPage: number) => {
      const newOffset = (newPage - 1) * limit
      setOffset(newOffset)
      setPage(newPage)
      setIsFilterClicked(true)
    },
    [limit],
  )

  React.useEffect(() => {
    defaultPageHandler()
  }, [card, id, status])

  const changeLimitHandler = React.useCallback(
    (limitOption: IOption) => {
      setLimit(+limitOption.name)
      setOffset(0)
      defaultPageHandler()
    },
    [page],
  )

  let hasPropertyMID = false
  let hasFingerprint = false
  if (dataTable) {
    hasPropertyMID = hasProperty(dataTable, 'client_merchant_id')
    hasFingerprint = hasProperty(dataTable, 'fingerprint')
  }

  const selectOptions: {
    label: string;
    name: string;
    status: string;
    id: number;
}[] = options.map((op) => {
    return {...op, label: op.name }
  })

  return (
    <Container>
      <SelectBarContainer>
        {windowWidth > 865 && (
          isLoadingHistory ?(
            <>
                <Skeleton variant="rounded" width={windowWidth > 920 ? 200 : 102} height={41} />
                <Skeleton variant="rounded" width={windowWidth > 920 ? 420 : 257} height={41} />
            </>
        ) : (
            <>
              <SelectsWrapper>
                <Option
                    active={typeOption === 'IN'}
                    onClick={() => {
                      setIsFilterClicked(true)
                      handleChangeActiveTypeOption('IN')
                    }}
                >
                  {t('components.in')}
                </Option>
                <Option
                  active={typeOption === 'OUT'}
                  onClick={() => {
                    setIsFilterClicked(true)
                    handleChangeActiveTypeOption('OUT')
                  }}
                >
                  {t('components.out')}
                </Option>
                <Option
                  active={typeOption === ''}
                  onClick={() => {
                    setIsFilterClicked(true)
                    handleChangeActiveTypeOption('')
                  }}
                >
                  {t('components.all')}
                </Option>
              </SelectsWrapper>
              <SelectsWrapper>
                {options.map((op) => (
                  <Option
                    key={op.name}
                    active={activeOption.status === op.status}
                    onClick={() => {
                      setIsFilterClicked(true)
                      handleChangeActiveOption(op)
                    }}
                  >
                  {t(`components.${op.name}`)}
                </Option>
                ))}
              </SelectsWrapper>
            </>
          )
        )}
        <div style={{  display: 'flex', gap: '6px', flexDirection: windowWidth > 435 ? 'row' : 'column' }}>
        {windowWidth <= 865 && (
          isLoadingHistory ?(
            <>
                <Skeleton variant="rounded" width={100} height={35} />
                <Skeleton variant="rounded" width={135} height={35} />
            </>
            ) : (
            <>
              <CustomSelect
                options={inOutOptions}
                width="100%"
                height="35px"
                backgroundColor="#fff"
                selectHandler={(e) => {
                  const required = inOutOptions.find(op => op.name === e.name)
                  if (required) {
                      setIsFilterClicked(true)
                      handleChangeActiveTypeOption(required.status)
                  }}}
                label=""
                selectedOption={{ name: inOutOptions.find(op => op.status === typeOption)?.name || 'all' }}
              />
              <CustomSelect
                options={selectOptions}
                width="145px"
                height="35px"
                backgroundColor="#fff"
                selectHandler={(e) => {
                  const required = options.find(op => op.name === e.name)
                    if (required) {
                      setIsFilterClicked(true)
                      handleChangeActiveOption(required)
                    }
                  }
                }
                label=""
                selectedOption={{ name: activeOption.name }}
              />
          </>
          )
        )}
        </div>
        {isLoadingHistory ? <Skeleton variant="rounded" width={33} height={36} />
        : (
        <FilterWrapper
          onClick={handleChangeActiveFilter}
          isActive={isActiveFilter}
        >
          <FilterIcon />
        </FilterWrapper>)}
        {isSubUser ?
            isLoadingHistory ? <Skeleton variant="rounded" width={150} height={58}/> : orders_access ?
                <CSVButton/> : null
            :
            isLoadingHistory ? <Skeleton variant="rounded" width={150} height={58}/> : <CSVButton/>
        }
      </SelectBarContainer>
      {isActiveFilter && (
        <Filter
          setCard={setCard}
          setId={setId}
          setFiatAmount={setFiatAmount}
          setTransationId={setTransationId}
          setMid={setMid}
          setFingerprint={setFingerprint}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          card={card}
          id={id}
          fiatAmount={fiatAmount}
          transationId={transationId}
          mid={mid}
          fingerprint={fingerprint}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
      {windowWidth <= 768 &&  (
        isLoadingHistory || (isFetchingHistory && isFilterClicked) ?
          <>
            {[...Array(10)].map((_, index) => (
              <div key={index} style={{ marginTop: '16px', marginLeft: windowWidth > 670 ? '54px' : 'auto' }}>
                <Skeleton variant="rounded" width={windowWidth > 670 ? 600 : undefined} height={250} />
              </div>
            ))}
          </>
        : !dataTable ? (
          <div style={{ paddingBottom: '200px' }}>
						{t('components.noData')}
					</div>
        ) :
        <>
          {dataTable?.map((item, index) => (
            <TransactionCard
              key={index}
              item={item}
              setIsVisiblePopupAppeal={setIsVisiblePopupAppeal}
              setSelectedId={setSelectedId}
              handleCallback={handleCallback}
              activeOption={activeOption}
              isVisiblePopupCheck={isVisiblePopupCheck}
              setIsVisiblePopupCheck={setIsVisiblePopupCheck}
              setCheckArray={setCheckArray}
            />
          ))}
        </>
      )}
      {windowWidth > 768 && (isLoadingHistory || (isFetchingHistory && isFilterClicked) ? (
              <Skeleton variant="rounded" height={800} />
            ) :
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th>
                      {t('components.status')}
                    </th>
                    <th>ID</th>
                    <th>
                      {t('components.clientTransactionId')}
                    </th>
                    {dataTable && hasPropertyMID && (
                      <th>
                        {t('components.clientMerchantId')}
                      </th>
                    )}
                    {dataTable && hasFingerprint && (
                      <th>
                        {t('components.fingerprint')}
                      </th>
                    )}
                    <th>
                      {t('components.requisites')}
                    </th>
                    {getCookie('is_fiat') === 'false' && (<th>
                      {t('components.send')}
                    </th>)}
                    {getCookie('is_fiat') === 'true' && (<th>
                      {t('components.amount')}
                    </th>)}
                    {getCookie('is_fiat') === 'false' && (<th>
                      {t('components.rate')}
                    </th>)}
                    <th>
                      {t('components.commission')}
                    </th>
                    {getCookie('is_fiat') === 'false' && (<th>
                      {t('components.get')}
                    </th>)}
                    {getCookie('is_fiat') === 'true' && (<th>
                      {t('components.total')}
                    </th>)}
                    <th>
                      {t('components.date')}
                    </th>
                    {isSubUser ?
                        !!tx_operations_access && <th>
                          {t('components.actions')}
                        </th>
                        :
                        <th>
                          {t('components.actions')}
                        </th>}

                  </tr>
                </thead>
                <tbody>
                {!dataTable ? (
                    <tr>
                      <td colSpan={
                          hasPropertyMID
                          ? hasFingerprint
                          ? 12
                          : 11
                          : 10
                        }>
                        {t('components.noData')}
                      </td>
                    </tr>
                  ) : (
                    dataTable?.map(
                      (item: IOrdersResponseData, index: number) => {
                        const dateCreate = new Date(item?.date_create).toLocaleString()
                        const dateComplete = item?.date_update ? new Date(item?.date_update).toLocaleString() : null
                        return (
                          <tr key={index}>
                            <td>
                              <StatusWrapper color={addStatusIcon(item?.status)}>
                                {t(`components.${findStatus(item?.status)}`)}
                              </StatusWrapper>
                            </td>
                            {getCookie('is_fiat') === 'false' && (<td>
                            {item.transaction_out ?
                              <CopyableText text={item?.transaction_out} truncatedLength={10} />
                                : '-'
                            }
                            </td>)}
                            {getCookie('is_fiat') === 'true' && (<td>
                            {item.tracker_id ?
                              <CopyableText text={item?.tracker_id} truncatedLength={10} />
                                : '-'
                            }
                            </td>)}
                            <td>
                              {item.client_transaction_id ?
                                <CopyableText text={item?.client_transaction_id} maxLength={30} />
                                  : '-'
                              }
                            </td>
                            {hasPropertyMID && (
                              <td>
                                <div>{item?.client_merchant_id?.slice(0, 10)}</div>
                              </td>
                            )}
                            {hasFingerprint && (
                              <td>
                                <div>{item?.fingerprint ? <CopyableText text={item?.fingerprint} truncatedLength={20} /> : null}</div>
                              </td>
                            )}
                            <td>
                              <div>{item?.receiver}</div>
                            </td>
                            {getCookie('is_fiat') === 'false' && (<td>
                              <div>
                                <div>
                                  {item?.token_from === 'USDTTRC' ? (
                                    <span
                                      style={{
                                        color: '#D65A4A',
                                        fontWeight: '600',
                                        fontSize: '17px',
                                      }}
                                    >
                                      -{' '}
                                      {rounding_format(item?.amount_from, 2, '.', ',')}
                                    </span>
                                  ) : (
                                    rounding_format(item?.amount_from, 2, '.', ',')
                                  )}
                                </div>
                                <div>
                                  <span>{item?.token_from}</span>
                                </div>
                              </div>
                            </td>)}
                            {getCookie('is_fiat') === 'true' && (<td>
                              <div>
                                <div>
                                  {rounding_format(item?.amount, 2, '.', ',')}
                                </div>
                                <div>
                                  <span>{item?.token}</span>
                                </div>
                              </div>
                            </td>)}
                            {getCookie('is_fiat') === 'false' && (<td>
                              <div>
                                {rounding_format(item?.exchange_rate, 2, '.', ',')}
                              </div>
                            </td>)}
                            {getCookie('is_fiat') === 'false' && (<td>
                                <div>
                                  {item?.commission &&
                                    rounding_format(item?.commission, 2)}
                                </div>
                              </td>)}
                              {getCookie('is_fiat') === 'true' && (<td>
                                <div>
                                  {item?.transaction_commission &&
                                    rounding_format(item?.transaction_commission, 2)}
                                </div>
                              </td>)}
                            {getCookie('is_fiat') === 'false' && (<td>
                              <div>
                                <div>
                                  {item?.token_to === 'USDTTRC' ? (
                                    <span
                                      style={{
                                        color: '#65B92F',
                                        fontWeight: '600',
                                        fontSize: '17px',
                                      }}
                                    >
                                      + {rounding_format(item?.amount_to, 2, '.', ',')}
                                    </span>
                                  ) : (
                                    rounding_format(item?.amount_to, 2, '.', ',')
                                  )}
                                </div>
                                <div>
                                  <span>{item?.token_to}</span>
                                </div>
                              </div>
                            </td>)}
                            {getCookie('is_fiat') === 'true' && (<td>
                              <div>
                                <div>
                                  {item?.type === 'IN' ? (
                                    <span
                                      style={{
                                        color: '#65B92F',
                                        fontWeight: '600',
                                        fontSize: '17px',
                                      }}
                                    >
                                      + {rounding_format(item?.total, 2, '.', ',')}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#D65A4A',
                                        fontWeight: '600',
                                        fontSize: '17px',
                                      }}
                                    >
                                      -{' '}
                                      {rounding_format(item?.total, 2, '.', ',')}
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <span>{item?.token}</span>
                                </div>
                              </div>
                            </td>)}
                            <td>
                              <DateWrapper>{dateCreate}</DateWrapper>
                              {(item?.status === 'EXPIRED' || item?.status === 'SUCCESS' || item?.status === 'ERROR') && dateComplete && (
                                <DateWrapper color={addStatusIcon(item?.status)}>
                                  {dateComplete}
                                </DateWrapper>
                              )}
                            </td>
                            {isSubUser ? !!tx_operations_access &&
                                <td>
                                  <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                      }}
                                  >
                                    {activeOption?.status === 'APPEAL' ||
                                    item?.status === 'APPEAL' ? (
                                        item?.appeal_link?.length ?? 0 > 0 ? (
                                            <Button disabled={!item?.appeal_link?.length} onClick={() => {
                                              setIsVisiblePopupCheck(!isVisiblePopupCheck)
                                              setCheckArray(item?.appeal_link || [])
                                            }} variant="primary">
                                              {t('components.inspect')}
                                            </Button>
                                        ) : null
                                    ) : (
                                        <CustomSelect
                                            options={item?.type === 'OUT' ? [cryptoActions[1]] : item?.status === 'EXPIRED' || item?.status === 'SUCCESS' ? cryptoActions : [cryptoActions[0]]}
                                            width="100%"
                                            height="35px"
                                            selectHandler={(e) => {
                                              if (e.name === 'appeal') {
                                                if (isSubUser) {
                                                  if (tx_operations_access > 1) {
                                                    setIsVisiblePopupAppeal(true)
                                                    setSelectedId(
                                                        getCookie('is_fiat') === 'false'
                                                            ? item?.transaction_out || ''
                                                            : item?.tracker_id || '',
                                                    )
                                                    setAmountFrom(getCookie('is_fiat') === 'false'
                                                        ? item?.amount_from || 0
                                                        : item?.amount || 0,
                                                    )
                                                  }
                                                } else {
                                                  setIsVisiblePopupAppeal(true)
                                                  setSelectedId(
                                                      getCookie('is_fiat') === 'false'
                                                          ? item?.transaction_out || ''
                                                          : item?.tracker_id || '',
                                                  )
                                                  setAmountFrom(getCookie('is_fiat') === 'false'
                                                      ? item?.amount_from || 0
                                                      : item?.amount || 0,
                                                  )
                                                }
                                              } else {
                                                handleCallback(item?.tracker_id || '')
                                              }
                                            }}
                                            label=""
                                            selectedOption={{name: 'actions'}}
                                        />
                                    )}
                                  </div>
                                </td>
                                :
                                <td>
                                  <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                      }}
                                  >
                                    {activeOption?.status === 'APPEAL' ||
                                    item?.status === 'APPEAL' ? (
                                        item?.appeal_link?.length ?? 0 > 0 ? (
                                            <Button disabled={!item?.appeal_link?.length} onClick={() => {
                                              setIsVisiblePopupCheck(!isVisiblePopupCheck)
                                              setCheckArray(item?.appeal_link || [])
                                            }} variant="primary">
                                              {t('components.inspect')}
                                            </Button>
                                        ) : null
                                    ) : (
                                        <CustomSelect
                                            options={item?.type === 'OUT' ? [cryptoActions[1]] : item?.status === 'EXPIRED' || item?.status === 'SUCCESS' ? cryptoActions : [cryptoActions[0]]}
                                            width="100%"
                                            height="35px"
                                            selectHandler={(e) => {
                                              if (e.name === 'appeal') {
                                                if (isSubUser) {
                                                  if (!!tx_operations_access) {
                                                    setIsVisiblePopupAppeal(true)
                                                    setSelectedId(
                                                        getCookie('is_fiat') === 'false'
                                                            ? item?.transaction_out || ''
                                                            : item?.tracker_id || '',
                                                    )
                                                    setAmountFrom(getCookie('is_fiat') === 'false'
                                                        ? item?.amount_from || 0
                                                        : item?.amount || 0,
                                                    )
                                                  }
                                                } else {
                                                  setIsVisiblePopupAppeal(true)
                                                  setSelectedId(
                                                      getCookie('is_fiat') === 'false'
                                                          ? item?.transaction_out || ''
                                                          : item?.tracker_id || '',
                                                  )
                                                  setAmountFrom(getCookie('is_fiat') === 'false'
                                                      ? item?.amount_from || 0
                                                      : item?.amount || 0,
                                                  )
                                                }

                                              } else {
                                                handleCallback(item?.tracker_id || '')
                                              }
                                            }}
                                            label=""
                                            selectedOption={{name: 'actions'}}
                                        />
                                    )}
                                  </div>
                                </td>
                            }
                          </tr>
                        )
                      },
                    )
                  )}
                </tbody>
              </Table>
            </TableWrapper>)}
      <PaginationWrapper>
        <div style={{
          height: '50px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <div style={{marginRight: '50px'}}>
            <Pagination
                page={page}
                offset={offset}
                limit={limit}
                onPageChange={handleOnChangePage}
                total={dataTable?.length}
                fetching={isFetchingHistory}
                loading={isLoadingHistory}
            />
          </div>
          <div>
            <CustomSelect
                options={limitOptions}
                width="70px"
                height="35px"
                label=""
                selectHandler={changeLimitHandler}
                fetching={isFetchingHistory}
                loading={isLoadingHistory}
            />
          </div>
        </div>
      </PaginationWrapper>
      <Popup
          isVisible={isVisiblePopupAppeal}
          setIsVisible={setIsVisiblePopupAppeal}
          minWidth="400px"
      >
        <AppealPopupContent>
          <div>
            {t('components.transactionId')} - {selectedId.slice(0, 10)}
          </div>
          <div>
            <StyledTextarea
                value={appealMessage}
                onChange={(e) => setAppealMessage(e.target.value)}
            />
          </div>
          <div>
            <InputWithLabel
                label="appealSum"
                type="number"
                placeholder="123.45"
                value={amountFrom}
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAmountFrom(e.target.value)
                }}
            />
          </div>
          <div>
            <DragNDrop isVisible={isVisiblePopupAppeal} setFiles64={setFiles64}/>
          </div>
          <div>
            {files64.length > 0
                ? files64.map((file: any, index) => (
                    <FileNamesWrapper key={'123123' + index}>
                      <div style={{flex: 1}}>{file.name}</div>
                      <DeleteIcon sx={{marginLeft: '8px'}}
                                  onClick={() => setFiles64((prev) => prev.filter((filePrev: any) => filePrev.name !== file.name))}/>
                    </FileNamesWrapper>
                ))
                : null}
          </div>
          <div>
            <Button disabled={files64.length === 0 || Number(amountFrom) < 0} onClick={appealHandler} variant="primary">
              {t('components.sendAppeal')}
            </Button>
          </div>
        </AppealPopupContent>
      </Popup>
      <ClickAwayListener onClickAway={() => setCheckArray([])}>
        <Popup
          isVisible={isVisiblePopupCheck}
          setIsVisible={setIsVisiblePopupCheck}
          minWidth="350px"
          width="450px"
        >
          {CustomSwiper(checkArray)}
        </Popup>
      </ClickAwayListener>
    </Container>
  )
}

export default React.memo(Main)
